<!--

      This view displays the page, which allows to replace a recipe from within
      a menu. This same view is re-used for the page, which allows to add a new
      recipe to a given menu (from within step 1 of the createplan wizard).

 -->

<template>
  <Page :title="this.title" v-if="this.$store.state.productreplace" :hidelargeheader="true">
    <div class="white-box" :key="this.$store.state.productreplace.product_id">
      <img class="current-product-image" :src="this.$store.state.productreplace.img_url + '?output_format=jpeg'" />
      <div class="headline">{{ $store.state.productreplace.product_name }}</div>
      <div class="product-box-lower-area">
        <div class="original-amount">
          <ion-skeleton-text animated="true" v-if="!this.fullProductData" class="skeleton"></ion-skeleton-text>
          <span class="original-amount-text" v-if="this.fullProductData" >{{ fullProductData.original_amount_unit }}</span>
        </div>
        <div class="product-amount-sel">
          <Price :price="this.$store.state.productreplace.price" class="prod-price"/>
          <AmountSelector
            :amount="this.$store.state.productreplace.quantity"
            :product="this.$store.state.productreplace"
          />
        </div>
      </div>
    </div>
    <div v-if="requestedAmount" class="white-box">
      <div v-if="this.recipeIds && this.recipeIds.length === 1" class="secondary-text-gray">
        Für dieses Rezept brauchst du {{ requestedAmountString }}.
      </div>
      <div v-if="this.recipeIds && this.recipeIds.length > 1" class="secondary-text-gray">
        Für diese {{recipeIds.length}} Rezepte brauchst du {{ requestedAmountString }}.
      </div>
      <div class="main-text" v-for="planrecipe in recipesWithThisProduct" :key="planrecipe.recipe.id" @click="openModal(planrecipe)">{{ planrecipe.recipe.name }}</div>
    </div>
    <ion-skeleton-text animated="true" v-if="requestedAmount === null" class="skeleton-current-product-label"></ion-skeleton-text>
    <div v-if="this.fullProductData && this.fullProductData.alternatives.length > 0">
      <div class="section-title">Alternative auswählen</div>
      <ion-skeleton-text animated="true" v-if="!this.fullProductData" class="skeleton-alternative"></ion-skeleton-text>
      <ProductSection :section="{ page: { results: fullProductData.alternatives} }" :useAsAlternative="true" />
    </div>
    <div v-if="this.additionalProductInfo && this.additionalProductInfoContainsValue">
      <ProductAdditionalInfo :additionalProductInfo="additionalProductInfo"/>
    </div>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import Price from '@/components/Price.vue'
import AmountSelector from '@/components/AmountSelector.vue'
import ProductSection from '@/components/shopping/ProductSection.vue'
import ProductAdditionalInfo from '@/components/shopping/ProductAdditionalInfo.vue'
import { IonSkeletonText } from '@ionic/vue'

export default {
  name: 'product',
  components: {
    Page,
    AmountSelector,
    Price,
    ProductSection,
    ProductAdditionalInfo,
    IonSkeletonText
  },
  data () {
    return {
      fullProductData: null,
      additionalProductInfo: null,
      requestedAmount: null,
      recipeIds: null,
      additionalProductInfoContainsValue: false,
    }
  },
  computed: {
    title () {
      if (this.$store.state.productreplace) {
        return this.$store.state.productreplace.shopping_ingredient_name
      }
    },
    requestedAmountString () {
      if (this.requestedAmount) {
        const amount = Math.round(this.requestedAmount)
        if (amount >= 1000) {
          return amount/1000 + 'kg'
        } else if (amount < 5) {
          return 'nur wenige Gramm'
        } else {
          return amount + 'g'
        }
      }
    },
    recipesWithThisProduct () {
      let allRecipesFromMenu = []
      if (this.$store.state.newplan && this.$store.state.newplan.single_days) {
        this.$store.state.newplan.single_days.forEach(day => {
          allRecipesFromMenu.push(day.planrecipes[0])
        })
      }
      let recipesToShow = []
      if (this.recipeIds) {
        this.recipeIds.forEach(rId => {
          const foundRecipe = allRecipesFromMenu.find(p => p.recipe.id === rId)
          if (foundRecipe) {
            recipesToShow.push(foundRecipe)
          }
        })
      }
      return recipesToShow
    },
  },
  methods: {
    retrieveAlternativeProducts () {
      if (this.$store.state.productreplace) {
        const details = {
          url: this.$store.state.api.shop.products + this.$store.state.productreplace.product_id + '/alternatives/'
        }
        this.$store.dispatch('apiSend', details).then(result => {
          if (result) {
            this.fullProductData = result
          }
        })
      }
    },
    retrieveAdditionalProductInfo () {
      // retrieves additional Product info such as nutrition table, manufacturer etc.
      if (this.$store.state.productreplace) {
        const details = {
          url: this.$store.state.api.shop.products + this.$store.state.productreplace.product_id + '/'
        }
        this.$store.dispatch('apiSend', details).then(result => {
          if (result) {
            this.additionalProductInfo = result
            this.checkAdditionalProductInfoContainsValue(result)
          }
        })
      }
    },
    checkAdditionalProductInfoContainsValue(additionalProductInfo) {
      // Checks specific attributes of additionalProductInfo for any attribute != null
      const attributesToCheck = ['manufacturer', 'additives', 'allergens', 'description', 'information', 'ingredients', 'nutritionTable']
      
      let foundValue = false
      attributesToCheck.forEach(attributeName => {
        foundValue += additionalProductInfo[attributeName] != null
      })

      this.additionalProductInfoContainsValue = foundValue
    },
    async retrieveRequestedAmount () {
      if (this.$store.state.newplan) {
        const details = {
          url: this.$store.state.api.plan.weekly + this.$store.state.newplan.id + '/aggregated-shopping-ingredients/'
        }
        this.$store.dispatch('apiSend', details).then(aggregatedShoppingIngredients => {
          if (aggregatedShoppingIngredients) {
            this.checkShoppingIngredientsForAmount(aggregatedShoppingIngredients)
            this.checkRecipes(aggregatedShoppingIngredients)
            this.$store.dispatch('save', { key: 'newordershoppingingredients', value: aggregatedShoppingIngredients })
        }
        })
      }
    },
    getShoppingIngredientIds () {
      // Collect all shopping ingredient ids of the aggregation of order products
      // Only order products, which have a shopping ingredient id are required for recipes.
      // All other products were added by the user manually and are thus not ingredients
      // for a specific recipe.
      if (this.$store.state.productreplace.originalOrderProducts) {
        let shoppingIngredientIds = []
        this.$store.state.productreplace.originalOrderProducts.forEach(op => {
          if (op.shopping_ingredient_id) {
            shoppingIngredientIds.push(op.shopping_ingredient_id)
          }
        })
        return shoppingIngredientIds
      }
    },
    checkShoppingIngredientsForAmount (aggregatedShoppingIngredients) {
      // This function has the goal to find out how much of a product the
      // user requires for his recipes. E.g. "You need 250g of potatos for your recipes."
      let requestedAmount = 0
      const shoppingIngredientIds = this.getShoppingIngredientIds()

      // For each of the collected shopping ingredients check, how much of it is needed.
      if (shoppingIngredientIds) {
        shoppingIngredientIds.forEach(shoppingIngredientId => {
          const shoppingIngredient = aggregatedShoppingIngredients.find(si => si.shopping_ingredient_id === shoppingIngredientId)
          if (shoppingIngredient && shoppingIngredient.requested_amount) {
            requestedAmount += shoppingIngredient.requested_amount
          }
        })
        this.requestedAmount = requestedAmount
      }
    },
    checkRecipes (aggregatedShoppingIngredients) {
      let recipeIds = []
      const shoppingIngredientIds = this.getShoppingIngredientIds()
      shoppingIngredientIds.forEach(shoppingIngredientId => {
        const shoppingIngredient = aggregatedShoppingIngredients.find(si => si.shopping_ingredient_id === shoppingIngredientId)
        if (shoppingIngredient && shoppingIngredient.recipes) {
          shoppingIngredient.recipes.forEach(si => {
            if (!recipeIds.find(r => r === si)) {
              recipeIds.push(si)
            }
          })
        }
      })
      this.recipeIds = recipeIds
    },
    image(recipe) {
      return process.env.VUE_APP_IMAGE_CDN + process.env.VUE_APP_IMAGE_FILTER3 + recipe.main_img
    },
    openModal(planrecipe) {
      this.$store.dispatch('openModal', {name: 'modalrecipedetails'})
      this.$store.dispatch('save', { key: 'modalrecipe', value: planrecipe.recipe } )
      this.$store.dispatch('save', { key: 'modalrplan', value: planrecipe } )
    },
  },
  mounted () {
    this.retrieveAlternativeProducts()
    this.retrieveAdditionalProductInfo()
    this.retrieveRequestedAmount()
    this.$store.dispatch('logAnalyticsEvent', { name: 'opened_product_alternative_page' })
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.current-product {
  margin: 20px;
  background: white;
  border-radius: $border-radius;
  padding: 10px;
}

.current-product-label {
  color: $col-light-gray-font; 
  font-size: 1em;
  margin: -5px auto 15px auto;
  width: 90%;
}

.current-product-image {
  width: 60vw;
  height: 60vw;
  margin: 0 auto;
  display: block;
}

.product-box-lower-area {
  border-top: solid 1px $col-lighter-gray-font;
  padding: 10px 0;
  text-align: left;
  margin-top: 10px;
}

.product-amount-sel {
  display: flex;
  justify-content: space-between;
}

.original-amount {
  display: block;
  font-size: .8em;
  color: $col-gray-font;
}

.original-amount-text {
  display: block;
  height: 18px;
}

.skeleton {
  width: 120px;
  height: 18px;
  margin-bottom: -4px;
}

.skeleton-alternative {
  min-height: 150px;
  margin: 10px auto;
  width: 90%;
}

.skeleton-current-product-label {
  width: 90%;
  height: 18px;
  margin: -5px auto 19px auto;
}

.no-alternatives {
  @extend .white-box;
  @extend .text-center;
  @extend .secondary-text-gray;
  background: $col-lighter-gray-font;
  box-shadow: none;
  height: 180px;
  width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.no-alternatives-icn {
  height: 48px;
  width: 48px;
  margin: 0 auto 10px auto;
  fill: $col-light-gray-font;
  --stroke: #{$col-light-gray-font};
}
</style>
