<template>
  <div class="section-title">Weitere Informationen</div>
  <div class="white-box">

    <!-- Information -->
    <BaseAccordion v-if="additionalProductInfo.information" headline="Information">
      <div class="secondary-text-gray">
        <div>{{ additionalProductInfo.information }}</div>
      </div>
    </BaseAccordion>

    <!-- Description -->
    <BaseAccordion v-if="additionalProductInfo.description" headline="Beschreibung">
      <div class="secondary-text-gray">
        <div>{{ additionalProductInfo.description }}</div>
      </div>
    </BaseAccordion>

    <!-- Ingredients -->
    <BaseAccordion v-if="additionalProductInfo.ingredients" headline="Zutaten">
      <div class="secondary-text-gray">
        <div>{{ additionalProductInfo.ingredients }}</div>
      </div>
    </BaseAccordion>

    <!-- Additives -->
    <BaseAccordion v-if="additionalProductInfo.additives" headline="Zusatzstoffe">
      <div class="secondary-text-gray">
        <div>{{ additionalProductInfo.additives }}</div>
      </div>
    </BaseAccordion>

    <!-- Allergens -->
    <BaseAccordion v-if="additionalProductInfo.allergens" headline="Allergene">
      <div class="secondary-text-gray">
        <div>{{ additionalProductInfo.allergens }}</div>
      </div>
    </BaseAccordion>

    <!-- Nutrition -->
    <BaseAccordion v-if="additionalProductInfo.nutrition_table" headline="Nährwerte">
      <div class="secondary-text-gray">
        <div class="nutrition-table-entry" v-for="nutritionTableEntry in additionalProductInfo.nutrition_table" :key="nutritionTableEntry.key">
          <div class="nutrition-table-grid" v-if="nutritionTableEntry.value">
            <span class="nutrition-table-key">{{ nutritionTableEntry.key }}:</span>
            <span class="nutrition-table-value">{{ nutritionTableEntry.value }}</span>
          </div>
        </div>
      </div>
    </BaseAccordion>

    <!-- Manufacturer -->
    <BaseAccordion v-if="additionalProductInfo.manufacturer" headline="Hersteller">
      <div class="secondary-text-gray">
        <div class="product-manufacturer-line" v-for="line in splitTextAtComma(additionalProductInfo.manufacturer)" :key=line>{{ line }}</div>
      </div>
    </BaseAccordion>

  </div>
</template>


<script>
import BaseAccordion from '@/components/accordions/BaseAccordion.vue'

export default {
  name: "additionalproductinfo",
  props: ['additionalProductInfo'],
  components: {
    BaseAccordion
  },
  methods: {
    splitTextAtComma(text) {
      return text.split(', ');
    },
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.nutrition-table-grid {
  display: grid;
  grid-template-columns: 60% 40%;
}

</style>